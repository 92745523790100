import { useState , useEffect} from 'react';
import { Users } from './helpers/Users';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {DataGridRow} from './interfaces/DataGridRow';
import {UserLicenseData} from './interfaces/UserLicenseData';
import { LicenseHelper, LicenseSummaryResult } from './helpers/License';
import {LicenseResultViewMRT} from './components/licenseResultViewMRT';
import { LicenseViewHeader } from './components/licenseViewHeader';
import LicenseTypeView from './components/headerView';
import { OriginalLicenseViewHeader } from './components/originalLicenseViewHeader';


export default function ExportDefaultToolbar() {

  const [usersState , setUserState ] = useState([] as UserLicenseData[]); /// user data state
  const [summarizedLicenses, setSummarizedLicenses] = useState({totalRemaining: 0, totalEnabled: 0, totalConsumed: 0}); /// license data state summarized

  const [newData, setNewData] = useState({columns: [
    { field: 'userPrincipalName', headerName: 'User Principal Name', flex: 2 },
    { field: 'licenseList', headerName: 'Assigned Licenses', flex: 2 },
    {
      field: 'hasValidPhoneLicense',
      headerName: 'Has Valid Phone License',
      flex: 1,
      renderCell: (params: any) => (
      params.value ? <CheckIcon style={{color: 'green'}} /> : <CloseIcon style={{color: 'red'}}/>
     )
    }
  ], rows: [] as DataGridRow[]});   /// data grid state


  /// extract token from url
  const queryParams = new URLSearchParams(window.location.search);
  const tokenId = queryParams.get("rd");


  /// fetch user data
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await Users.getUsersFromAPI(tokenId as string);
        if (userList.licensedUsers){
          setUserState(userList.licensedUsers); // Assuming userList is already the correct type
        }

        if(userList.phoneEnabledLicenseSKUs){
          const licenses : LicenseSummaryResult = LicenseHelper.summarizeLicenses(userList?.phoneEnabledLicenseSKUs);
          setSummarizedLicenses({totalRemaining: licenses.totalRemaining, totalEnabled: licenses.totalEnabled, totalConsumed: licenses.totalConsumed});
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (tokenId) {
      fetchUsers();
    }
    else {
      window.location.href = "https://license-checker.mybridges.cloud/licensing/"; // Redirect to home page if token is not found
    }
  }, [tokenId]);


  /// Update rows when usersState changes
  useEffect( () => {
      const theRows =  LicenseHelper.parseLicenseData(usersState) ;
      if ( theRows.length > 0)
        setNewData({
          columns: newData.columns, // Keep the existing columns
          rows: theRows
        });
  }, [usersState]);


  return (
    <div>
      <LicenseViewHeader />
      {/* <OriginalLicenseViewHeader /> */}
      <LicenseTypeView totalEnabled={summarizedLicenses.totalEnabled} totalRemaining={summarizedLicenses.totalRemaining} />
      <LicenseResultViewMRT data={newData.rows} />
    </div>
  );
}
