import { Container } from "@mui/material";
import "../styles/header.css";

export const LicenseViewHeader = () => {
  return (
    <Container>
      <header className="header">
      <div className="logo">
        <img src="./NUSO-Bridge-Logo-Vector.png" alt="Logo" height="65" width="126" />
      </div>
      <div className="header-text">
        <h1>Microsoft Teams License Checker</h1>
      </div>
    </header>
    </Container>
  )
}