import { Table, TextField, TableRow, TableCell } from '@mui/material';


interface SummaryTilesComponentPros {
  totalEnabled: number;
  totalRemaining: number;
  showTitle?: boolean;
}

export const SummaryTilesComponent = (props: SummaryTilesComponentPros) => {

  const MINIMUM_PHONE_LICENSES = 2;
  const CELL_WIDTH: string = `33.33%`;
  const CELL_BG_COLOR: string = 'rgba(237, 237, 237, 0.5)';

  const getTotalEnabledLicenses = () => {
    return props.totalEnabled;
  }

  const getTotalRemainingLicenses = () => {
    return props.totalRemaining;
  }

  const getAdditionalLicensesRequired = () => {
     return props.totalRemaining < MINIMUM_PHONE_LICENSES ?
       (MINIMUM_PHONE_LICENSES - props.totalRemaining)
       : 0;
  }


  return (
      <Table style={{ flex: 'flex-grow' }}>
        <TableRow>
          <TableCell style={{width: CELL_WIDTH }}>
            <TextField label={'Phone Licenses You Have'} fullWidth value={`${getTotalEnabledLicenses()}`} size="medium" sx={{backgroundColor: CELL_BG_COLOR }}
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center', fontSize: '1.1rem', fontWeight: 'bold'} }
              }}/>
          </TableCell>
          <TableCell style={{width: CELL_WIDTH}}>
            <TextField  label={'Unallocated Phone Licenses'} fullWidth value={`${getTotalRemainingLicenses()}`}  size="medium" sx={{backgroundColor: CELL_BG_COLOR}}
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' , fontSize: '1.1rem', fontWeight: 'bold' } }
              }}/>
          </TableCell>
          <TableCell style={{width: CELL_WIDTH}}>
            <TextField label={'Minimum Additional Required Phone Licenses'} fullWidth value={getAdditionalLicensesRequired()}  size="medium" sx={{backgroundColor: CELL_BG_COLOR}}
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center', fontSize: '1.1rem' , fontWeight: 'bold' } }
              }}/>
          </TableCell>
        </TableRow  >
      </Table>
  )

}